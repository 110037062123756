<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';
import { toRaw } from 'vue';
// import { latLng } from "leaflet";
import Swal from 'sweetalert2';
import { VueFileAgent } from '@boindil/vue-file-agent-next';
import Multiselect from "@vueform/multiselect";
import axios from 'axios';

import { firebase } from "../../../services/firebase";

export default {
  setup() {
  },
  components: {
    Layout,
    PageHeader,
    VueFileAgent,
    Multiselect,
  },
  data() {
    return {
      zoom: 12,
      maxZoom: 25,
      map: null,
      center: {
        lat: null,
        lng: null
      },
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      markers: [],
      selectedPosition: {
        lat: null,
        lng: null
      },
      title: "Lancer une nouvelle interventions",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Nouvelle intervention",
          active: true,
        },
      ],
      sources: [
        { value: 'alerte', label: 'Alerte' },
        { value: 'téléphone', label: 'Appel Téléphonique' },
        { value: 'renseignement', label: 'Renseignement' },
      ],
      spanView: false,
      isRecording: false,
      mediaRecorder: null,
      chunks: [],
      audioURL: null,
      alert: {},
      agentsPosition: [],
      data: {
        agent: null,
        short_code: null,
        title: null,
        lieu: null,
        descrition: null,
        selected: false,
        position: null,
        photos: [],
        audios: [],
        videos: [],
        audiosLocal: [],
        files: [],
      },
      makerpselected: null,
      agent: null,
      Marker: [],
      filesData: [],
      options: ["Sécurité Nationale", "Appel à témoin", "Sensibilisation"],
      zones: ["Zone 1", "Zone 2", "Zone 3"],
      zone: null,
      thematique: null,
      reqError: null,
      reqResp: null

    };
  },
  mounted() {
    !navigator.mediaDevices.getUserMedia ? alert('Votre navigateur ne supporte pas l\'enregistrement audio.') : null;
    this.initMap();
    const that = this;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      }, function (error) {
        alert("Erreur de géolocalisation : " + error.message);
      });
    } else {
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    initMap() {
      this.map = new google.maps.Map(this.$refs["map"], {
        zoom: 10,
        center: new google.maps.LatLng(6.247046, 1.311771),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      this.map.addListener("click", (e) => {
        this.handleMapClick(e)
      });

      this.getAgentPositionFromFirestore();
    },
    async getAgentPositionFromFirestore() {
      const self = this;
      firebase.firestore()
        .collection("partages_position")
        .onSnapshot(snap => {
          const agentsPosition = [];
          const infoWindows = [];
          const iconCommon = {
            url: "/maps/gpscar.png",
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(0, 0),
          };

          const icons = {
            moto: { ...iconCommon, url: "/maps/gpsmoto.png" },
            car: { ...iconCommon, url: "/maps/gpscar.png" },
            pieds: { ...iconCommon, url: "/maps/mapuserwalker.png" },
            helico: { ...iconCommon, url: "/maps/gpscar.png" },
          };

          console.log("snap.docs.length")
          console.log(snap.docs.length)
          for (let i = 0; i < snap.docs.length; i++) {
            var driver = snap.docs[i].data();
            const finalIcon = icons[driver.type_service] || iconCommon;
            const nmaker =
              new google.maps.Marker({
                map: this.map,
                title: driver.type_service,
                icon: finalIcon
              })
            nmaker.addListener("click", ({ domEvent, latLng }) => {
              const { target } = domEvent;
              self.position(driver)
            });

            agentsPosition.push(nmaker);
            agentsPosition[i].setPosition(
              new google.maps.LatLng(driver.latitude, driver.longitude)
            );
            console.log(driver)
            infoWindows.push(new google.maps.InfoWindow({
              disableAutoPan: true
            }));
            /* infoWindows[i].setContent(
                 `<div class="ui header">Agent A </div>`
             );
             infoWindows[i].open(this.map, agentsPosition[i]);*/

          }
        });
    },
    ...reqMethods,
    /* getAgentPosition() {
       const that = this;
       this.getRequest('/account/agents/agent/service/')
           .then(
               function (response) {
                 for (const position of response.data) {
                   that.markers.push({
                     id: position.id,
                     position: position.typeservice?.position?.coordinates,
                     name: position.username,
                     typeAgent: position.typeservice?.type_service,
                     agenID: position.typeservice?.agent,
                     picture: position.picture
                   });
                 }
               },
               function (error) {
                 that.reqError = error;
               }
           )
     },*/
    position(data) {
      const that = this;
      Swal.fire({
        title: data.agent,
        text: "Souhaitez-vous affecter cette intervention à cet agent ?",
        imageHeight: 40,
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Oui, Affecter !",
      }).then(result => {
        that.data.agent = data.agentId;
        if (result.value) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Affectation effectuée avec succès",
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.addEventListener('dataavailable', this.onDataAvailable);
        this.mediaRecorder.addEventListener('stop', this.onRecordingStopped);
        this.chunks = [];
        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error('Erreur lors de la demande d\'accès à l\'audio:', error);
      }
    },

    async convertBlobToAudioFile(blobUrl) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();

      const currentDate = new Date();
      const fileName = currentDate.toLocaleString('fr-FR', { timeZone: 'UTC' })
        .replace(/[/:]/g, '-') + '.wav';

      const file = new File([blob], fileName, { type: 'audio/wav' });
      return file;
    },

    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
    },

    onDataAvailable(event) {
      event.data.size > 0 ? this.chunks.push(event.data) : null;
    },

    async onRecordingStopped() {
      const audioBlob = new Blob(this.chunks, { type: 'audio/wav' });
      const audioURL = URL.createObjectURL(audioBlob);
      this.data.audiosLocal.push(audioURL);
      const audioFile = await this.convertBlobToAudioFile(audioURL);
      this.data.audios.push(audioFile);
    },

    deleteAudio(index) {
      this.data.audiosLocal.splice(index, 1);
      this.data.audios.splice(index, 1);
    },
    handleMapClick(event) {
      if (event && event.latLng) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        this.data.position = `POINT(${lat} ${lng})`;
        this.selectedPosition.lat = lat;
        this.selectedPosition.lng = lng;
        const self = this;
        self.deleteMarkers();

        const mmker = new google.maps.Marker({
          position: event.latLng,
          map: self.map,
          title: "Position selectionnée",
        });

        self.markers.push(mmker);

      }
    },
    // Sets the map on all markers in the array.
    setMapOnAll(map) {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers.map((marker) => toRaw(marker).setMap(null))
        this.markers[i].setMap(map);
      }
    },
    hideMarkers() {
      this.setMapOnAll(null);
    },

    deleteMarkers() {
      this.hideMarkers();
      console.log(this.markers);
    },


    filterFiles() {
      this.filesData.forEach(element => {
        if (element.file.type.includes('image/')) {
          this.data.photos.push(element.file);
        } else if (element.file.type == 'application/pdf') {
          this.data.files.push(element.file);
        } else if (element.file.type.includes('video/')) {
          this.data.videos.push(element.file);
        } else if (element.file.type.includes('audio/')) {
          this.data.audios.push(element.file);
        }
      })
    },
    async lunchIntervention() {
      const that = this;
      this.spanView = true;
      ((this.filesData.length !== 0) ? this.filterFiles() : null);
      const formData = new FormData();
      formData.append('agent', parseInt(this.data.agent));
      formData.append('from_alert', false);
      formData.append('title', this.data.title);
      formData.append('lieu', this.data.lieu);
      formData.append('information', this.data.information);
      if (this.data.information == 'téléphone') {
        formData.append('cellnumber', this.data.cellnumber);
      } else if (this.data.information == 'alerte') {
        formData.append('short_code', this.data.short_code);
      }
      formData.append('position', this.data.position);
      formData.append('description', this.data.descrition);
      const fileCategories = ['photos', 'videos', 'audios', 'files'];
      for (const category of fileCategories) {
        for (const file of this.data[category]) {
          formData.append(category, file);
        }
      }
      formData.append('from_alert', true);
      for (const [key, value] of formData.entries()) {
        if (!value && !['photos', 'videos', 'audios', 'files'].includes(key)) {
          Swal.fire({
            title: 'Erreur',
            text: `Le champ ${key} est réquis et ne peut pas être vide.`,
            icon: 'error',
            confirmButtonClass: 'btn btn-primary w-xs mt-2',
            buttonsStyling: false,
            showCloseButton: true,
          });
          that.spanView = false;
          return;
        }
      }
      try {
        const response = await axios.post(
          'https://api.alcit.sims-technologie.com/api/v1/intervention/intervention/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          }
        );
        that.spanView = false;
        Swal.fire(response.data.message, "L'intervention à été lancée!", "success");
        console.log(response);
        this.$router.push({ path: `/interventionProgress/${response.data.id}` });
      } catch (error) {
        that.spanView = false;
        that.reqError = error;
        console.log(error);
        Swal.fire(response.data.message, "L'intervention à été lancée!", "success");
        console.log(response);
        this.$router.push({ path: `/interventionProgress/${response.data.id}` });
        // Swal.fire({
        //   title: "Oops...",
        //   text: "Erreur lors du lancement de l'intervention",
        //   icon: "error",
        //   confirmButtonClass: "btn btn-primary w-xs mt-2",
        //   buttonsStyling: false,
        //   showCloseButton: true,
        // });
      }
    },
    handleMarkerClick() {
      this.Marker = [];
      this.selectedPosition = [];
      this.data.position = [];
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Source d'information</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.information" :options="sources">
                  </multiselect>
                </div>
              </div>
              <div class="form-group row mb-4" v-if="data.information == 'alerte'">
                <label for="title" class="col-form-label col-lg-2">Code d'alerte<span
                    class="text-danger">(*)</span></label>
                <div class="col-lg-10">
                  <input id="code" v-model="data.short_code" name="code" type="text" class="form-control"
                    placeholder="Renseigner le code de l'alerte" />
                </div>
              </div>
              <div class="form-group row mb-4" v-if="data.information == 'téléphone'">
                <label for="title" class="col-form-label col-lg-2">Numéro de téléphone<span
                    class="text-danger">(*)</span></label>
                <div class="col-lg-10">
                  <input id="code" v-model="data.cellnumber" name="code" type="text" class="form-control"
                    placeholder="Numéro de téléphone" />
                </div>
              </div>
              <div class="form-group row mb-4" v-if="data.information == 'renseignement'">
                <label for="title" class="col-form-label col-lg-2">Obsevation<span class="text-danger">(*)</span></label>
                <div class="col-lg-10">
                  <input id="code" v-model="data.obsevation" name="code" type="text" class="form-control"
                    placeholder="Observation" />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Titre</label>
                <div class="col-lg-10">
                  <input id="title" v-model="data.title" name="title" type="text" class="form-control"
                    placeholder="Saisir le titre de l'alerte" />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Lieux</label>
                <div class="col-lg-10">
                  <input id="adresse" v-model="data.lieu" name="adresse" type="text" class="form-control"
                    placeholder="Renseigner l'adresse" />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2">Enregistrer audio</label>
                <div class="col-lg-10">
                  <div class="audio-recorder">
                    <div class="recorder-controls">
                      <button type="button" @click="startRecording" :disabled="isRecording"
                        :class="{ 'record-button': true, 'pulse': isRecording }">
                        <span v-if="!isRecording"><i class="mdi mdi-microphone-outline"></i></span>
                        <span v-else><i class="mdi mdi-microphone-settings"></i></span>
                      </button>
                      <button type="button" @click="stopRecording" :disabled="!isRecording" class="stop-button"><i
                          class="mdi mdi-stop"></i>
                      </button>
                    </div>
                    <div class="audio-player" v-if="data.audiosLocal.length > 0">
                      <div class="audio-item" v-for="(audio, index) in data.audiosLocal" :key="index">
                        <audio :src="audio" controls></audio>
                        <button type="button" @click="deleteAudio(index)" class="delete-button">
                          <i class="mdi mdi-close"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2">Fichiers associés</label>
                <div class="col-lg-10">
                  <VueFileAgent :deletable="true" :maxSize="'50MB'" :maxFiles="20" :meta="true" :multiple="true"
                    :uploadUrl="uploadUrl" :accept="'image/*,video/*,audio/*,.pdf'" v-model="filesData" />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Coordonnées géographique</label>
                <div class="col-lg-10">
                  <div class="row">
                    <div class="col-lg-6">
                      <input id="lat" placeholder="latitude" :disabled="true" v-model="selectedPosition.lat" type="text"
                        name="latitude" class="form-control" />
                    </div>
                    <div class="col-lg-6">
                      <input id="lat" placeholder="longitude" :disabled="true" v-model="selectedPosition.lng" type="text"
                        name="longitude" class="form-control" />
                    </div>
                  </div>
                </div>
                <label for="title" class="col-form-label col-lg-2"></label>
                <div class="col-lg-10">
                  <section style="height: 700px;" ref="map" class="map"></section>
                  <!--                  <l-map :zoom="zoom" style="height: 500px;" :center="center" @click="handleMapClick">
                                      <l-tile-layer :url="url" :maxZoom="maxZoom" />
                                      <l-marker v-for="( marker, index ) in Marker" :key="index" :visible="true" :lat-lng="marker.position"
                                        @click="handleMarkerClick()">
                                        <l-icon :icon-anchor="staticAnchor">
                                          <img src="@/assets/images/leaflet/marker-icon.png" />
                                        </l-icon>
                                      </l-marker>
                                      <l-marker v-for="marker in markers" :key="marker.id" :visible="marker.visible"
                                        :draggable="marker.draggable" :lat-lng="marker.position" @click="position(marker)">
                                        <l-icon v-if="marker.typeAgent == 'moto'" :icon-anchor="staticAnchor">
                                          <img src="@/assets/images/leaflet/motorbike.png" />
                                        </l-icon>
                                        <l-icon v-if="marker.typeAgent == 'voiture'" :icon-anchor="staticAnchor">
                                          <img src="@/assets/images/leaflet/police.png" />
                                        </l-icon>
                                        <l-icon v-if="marker.typeAgent == 'pied'" :icon-anchor="staticAnchor">
                                          <img src="@/assets/images/leaflet/walking.png" />
                                        </l-icon>
                                      </l-marker>
                                    </l-map>-->
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Message d'intervention</label>
                <div class="col-lg-10">
                  <textarea id="projectdesc" v-model="data.descrition" class="form-control" rows="4"
                    placeholder="Donnez des instruction détaillés"></textarea>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-10">
                  <button type="reset" title="Ne pas diffuser maintenant" class="btn btn-primary">
                    Annuler l'intervention
                  </button>
                  <button @click="lunchIntervention()" type="button" class="btn btn-success" style="float: right"
                    :disabled="spanView">
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                    Lancer l'intervention
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.recorder-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pulse:before {
  content: '';
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
  transition: opacity .3s, transform .3s;
  animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  z-index: 1;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.record-button {
  background-color: #28a745;
  position: absolute;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: -50;
}

.stop-button {
  background-color: #dc3545;
  position: absolute;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
}

.audio-player {
  width: 100%;
}

.audio-item {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}
</style>